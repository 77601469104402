.MenuItem {
    margin: 5px;
    padding: 5px;
}
.TextUpdate{
    margin-left: 880px;
    margin-right: 815px;
    font-size: 30px;
    font-weight: bold;
}
