.adminlogin-container {
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.ondemand-logo {
    width: 170px;
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 30px;
    cursor: pointer;
}

.ondemand-rewards-logo {
    width: 200px;
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 15px;
    margin-right: 30px;
    cursor: pointer;
}

.google-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.google-logo {
    margin-right: 20px;
}
