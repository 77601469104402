.container.regulations {
  margin-top: 40px;
}

.text-box {
    padding-left: 12rem;
    padding-right: 12rem;
    margin-bottom: 2rem;

    max-height: 30rem;
    overflow-y: auto;
  }


  @media screen and (max-width: 1024px) {
    .text-box {
        padding-left: 2rem;
        padding-right: 2rem;
      } 
   }
