.promotion-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  flex-direction: column;
}

.buttonCard-container {
  display: flex;
  margin: 30px;
  justify-content: center;
  align-items: center;
}

.btn-card {
  background-color: var(white);
  color: var(--black);
  margin: 10px;
  padding: 10px;
  border-radius: 20px;
  margin: 30px;

  font-size: 1.25rem;
  font-weight: 600;
}

.link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

.link::before,
.link::after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--red);
  transform: scaleX(0);
  transition: transform 0.25s;
}

.link::before {
  top: -3px;
  transform-origin: left;
}

.link::after {
  bottom: -3px;
  transform-origin: right;
}

.link:hover:before,
.link:hover::after {
  transform: scaleX(1);
}


.card-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  margin-bottom: 30px;
}

.cardlist-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
}

.viewlibrary-container {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center; 
  align-items: center;
  z-index: 10;
  background-color: var(--white);
}
