.user-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    background-color: var(--grey);
}

.user-profile-canvas {
    display: flex;
    flex-direction: column;
    background-color: var(--red);
    align-items: center;
    justify-content: center;
    width: 30rem;
    margin: 1.5rem 0;
    border-radius: 15px;
}

.user-profile-canvas .name-title {
    background-color: var(--red);
    color: var(--white);
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem;
}

.user-profile-canvas .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    font-weight: 600;
    padding: 1rem;

    background-color: var(--white); 
    width: 100%;

    border-end-end-radius: 15px;
    border-end-start-radius: 15px;
}

.user-profile-canvas .info h1 {
    font-size:  3rem;   
}

/* Responsive Settings */
@media screen and (max-width: 420px) {
    .user-profile-canvas {
        flex-direction: column;
        width: 100%;
    }

    .user-profile-right {
        margin-left: 0;
    }

    .user-profile img {
        display: none;
    }

    .user-profile h1 {
        font-size: 2rem;
    }
   }
