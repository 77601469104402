  .Admin-background {
    background-color: var(--white);
  }
  
  .maincard{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-submit{
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
  }

  .button-add{
    display: flex;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
  }

.left {
  margin-top: 60px;
  margin-left: 50px;
  margin-right: 50px;
}

.right {
  margin-bottom: 355px;
  margin-left: 50px;
  margin-right: 50px;
}

.content {
    margin-top: 3rem;
}

.line1 {
  margin-top: 55px;
  margin-bottom: 40px;
  margin-left: 410px;
  margin-right: 315px;
  height: 5px;
  background: linear-gradient(
    to bottom,
    transparent 2px,
    #DDE2E5 2px,
    #DDE2E5 3px,
    transparent 3px
  );
}

.left2 {
  margin-left: 500px;
  margin-right: 500px;
}

.right2 {
  margin-left: 400px;
  margin-right: 400px;
}

.left3 {
  margin-left: 5px;
  margin-right: 5px;
}

.right3 {
  margin-left: 5px;
  margin-right: 5px;
}

.top1{
  margin-top: 20px;
  margin-bottom: 5px;
}

.switch1{
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
}
