.adminlist-contrainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
}

.admimlist-title {
}

.adminlist-header {
}

.adminlist-table {
    height: 370px;
    width: 750px;
    align-items: center;
}
