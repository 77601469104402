.Admin-background {
    background-color: var(--white);
}


.button-submit {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
}

.button-add {
    display: flex;
    align-items: center;
    justify-content: center;
}


.content {
    margin: 3rem;
}

.switch1 {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}
