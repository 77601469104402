  /* Campaigns */
  .campaign {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .campaign-image img,
  .shoponline-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    width: 30rem;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }

  .shoponline-image,
  .campaign-image {
    display: flex;
    overflow-wrap: normal;
    overflow-y: auto;
  }

  .reward-campaigns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
  }
