  /* Maincover */
  .container.maincovers,
  .container.regulations {
    background-color: var(--red);
  }

  .container.maincovers {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .container.shoponline-campaigns {
    margin-bottom: 2rem;
  }

  .container.regulations {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .maincover {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
  }

  .maincover img {
    width: 50rem;
  }
