/* Footer */
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    color: var(--black);
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .footer img {
    width: 175px;
    margin-bottom: 20px;
    position: relative;
    left: -8px;
  }

  .company-address {
    margin-top: 10px;
  }

  .copyright {
    margin-top: 10px;
  }

  .contact-list {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }

  .contact-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .contact-icon {
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
  }

/* Responsive Settings */

  @media screen and (max-width: 470px) {
    .footer-left {
        margin-right: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .footer h1 {
      font-size: 1.5rem;
    }
    .footer h2 {
      font-size: 1.25rem;
    }
  }
