@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 100px;
    height: 100px;
    border: 10px solid #f0a5a5;
    border-top: 10px solid #5b1a1a;
    border-radius: 50%;
    animation: spinner 2.0s linear infinite;
    z-index: 1010;
}

.block-ui-container {
    background-color: #f3f3f3;
    opacity: 0.8;
    position: fixed;
    z-index: 1010;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 150%;
    min-height: 100%;
    width: 100%;
    cursor: wait;
    overflow: hidden;
}
