/* Navbar */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
  }
  
  .navbar img {
    width: 175px;
    cursor: pointer;
  }
