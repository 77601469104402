@font-face {
    font-family: dbheavent;
    src: url(./Assets/Fonts/DB\ Heavent\ v3.2.1.ttf);
}
/* Reset Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --red: #D7282F;
  --red-hover: #cb1622;
  --white: #ffffff;
  --grey: #9E9FA1;
  --dark: #4a4a4a;
  --black: #000000;
}

body {
    font-family: "dbheavent";
    scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: var(--black);
}

li {
  list-style: none;
}

img {
  max-width: 100%;
  border-radius: 15px;
}

input {
  border: none;
  outline: none;
}

p {
  font-size: 1.5rem;
}

/* Global Styles */
.container {
  position: relative;
  z-index: 5;
  padding: 0 5rem;
  margin: 0 auto;
}

.canvas {
  background-color: var(--white);
  padding: 2rem 2rem;
  border-radius: 50px;
}

.title-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 40px;
  font-weight: 600;
  color: var(--red);
  padding: 2rem;
}

.title span {
  color: var(--black);
}

/* Responsive Settings */
   @media screen and (max-width: 600px) {
    .container.maincovers {
        display: none;
    }
    .container {
        padding: 0 1rem;
    }
    .canvas {
        padding: 1rem;
    }
    p {
        font-size: 16px;
    }
    .title-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
   }
   
   @media screen and (max-width: 768px) {
    .title,
    .title span {
        font-size: 32px;
    }
    .title-regulation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .title-regulation span {
        margin: 0.2rem;
    }
   }
   
   @media screen and (max-width: 975px) {
    .maincover img {
        width: 35rem;
      }    
   }

   @media screen and (max-width: 1024px) {
    .text-box {
        padding-left: 2rem;
        padding-right: 2rem;
      } 
   }
